import React from 'react';
import './index';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>QuixVid</h1>
        <p>Coming soon!</p>
      </header>
    </div>
  );
}

export default App;
